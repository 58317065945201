<template>
  <edit-dialog
    ref="editor"
    :data="pixel"
    :el-rules="elementRules"
    object-name="IP List"
    :override-title="overrideTitle"
    label-width="150px"
    :visible="visible"
    :save-disabled="cannotUpload"
    :save-state="saving"
    width="50%"
    @request-close="resetForm"
    @save="handleSave"
  >
    <template slot-scope="{ formData, error }">
      <div>
        <div v-show="error">
          <el-alert type="error">
            {{ error }}
          </el-alert>
        </div>

        <div class="form">
          <div v-if="!existing">
            <el-form-item
              label="IP List Name"
              prop="name"
            >
              <el-input v-model="formData.name" />
            </el-form-item>
            <el-form-item
              label="Organization"
              prop="organization"
            >
              <org-picker
                has-pixel-id
                :organization.sync="formData.organization"
              />
            </el-form-item>
          </div>
          <el-form-item
            label="IP List File"
          >
            <uploader
              ref="uploader"
              api-endpoint="/api/v1/ipmaid/upload"
              single-file
              upload-field-name="imports"
              @complete="uploadComplete"
              @error="handleError"
              @file-change="handleFileChange"
              @upload-state-change="handleStateChange"
            />
            <p>
              File must be a <pre>.csv</pre> with one IP address per line.
              Based on the current time, this file will be associated to the date: <pre>{{ fileDate }}</pre>
            </p>
            <p>
              <strong>Please note:</strong> IP Addresses are processed in a daily batch, so it may be several hours before newly added data is accessible.
            </p>
          </el-form-item>
          <el-card
            v-if="isInternal"
            shadow="never"
            class="internal"
          >
            <div slot="header">
              <span>Pixel Partner Override</span>
            </div>
            <p>
              If set, this will override the default pixel partners for this pixel's organization.
            </p>
            <el-select
              v-model="formData.pixel_partners"
              multiple
            >
              <el-option
                v-for="partner in pixelPartners"
                :key="partner"
                :label="partner"
                :value="partner"
              />
            </el-select>
          </el-card>
        </div>
      </div>
    </template>
  </edit-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import OrgPicker from '@/components/global/OrgPicker.vue';
import EditDialog from '@/components/global/EditDialog.vue';
import Uploader from '@/components/global/Uploader.vue';
import { isUTF8 } from '@/helpers';
import papa from 'papaparse';
import { pixelPartners } from '@/constants';

export default {
  components: { EditDialog, OrgPicker, Uploader },
  props: {
    ip: { type: Object, default: () => ({}) },
    visible: { type: Boolean, required: true },
  },

  data() {
    return {
      fileList: [],
      pixel: {
        name: null,
        organization: { id: this.$store.state.user.orgDetails.id },
      },
      elementRules: {
        name: [
          {
            required: true,
            message: 'IP name is required.',
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (value.trim().length < 1) {
                callback(new Error('IP name is required.'));
              }
              callback();
            },
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (!value.match(/^[a-z0-9_]+$/i)) {
                callback(new Error('Only alpha-numeric and underscores are accepted. No whitespace or other special characters.'));
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        organization: [
          {
            validator(rule, value, callback) {
              if (!value) {
                callback(new Error('Please choose an organization.'));
              } else {
                callback();
              }
            },
          },
        ],
      },
      loading: false,
      saving: false,
    };
  },

  computed: {
    ...mapGetters('user', ['hasPermission', 'isInternal']),

    cannotUpload() {
      return this.fileList.length < 1;
    },

    existing() {
      return !!this.ip.id;
    },

    fileDate() {
      return moment.utc().format('YYYY-MM-DD');
    },

    overrideTitle() {
      if (this.ip.id) {
        return `Add data to ${this.ip.name}`;
      }
      return 'Create a new IP Address List';
    },

    pixelPartners() {
      return pixelPartners;
    },
  },

  watch: {
    ip (value) {
      if (value.name) {
        this.pixel.name = value.name;
      }
    },
    visible (value) {
      this.$refs.editor.reset();
    },
  },

  methods: {
    async handleFileChange(fileList) {
      this.loading = true;

      const utf8 = await isUTF8(fileList[0]);
      if (!utf8) {
        this.$refs.uploader.reset();
        this.$notify.error({
          message: 'File is not UTF-8 encoded.',
        });
        this.loading = false;
        return;
      }

      let badRows = 0;
      let goodRows = 0;
      papa.parse(fileList[0], {
        header: false,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: async (results, file) => {
          results.data.map(row => {
            if (!/\d+.\d+.\d+.\d+/.test(row[0])) {
              badRows++;
            } else {
              goodRows++;
            }
          });

          if (badRows) {
            this.$notify.warning({
              message: `Your file contains malformed ${badRows} IP address${badRows !== 1 ? 'es' : ''} that will be ignored.`,
            });
          }
          if (goodRows > 0) {
            this.fileList = fileList;
          }
        },
      });
    },
    handleError(error) {
      this.$notify.error({
        message: error.message,
      });
    },
    handleStateChange(isUploading) {
      this.loading = isUploading;
    },
    uploadComplete(result) {
      if (!result.status === 'success') {
        return this.$notify.error({
          message: 'Your request was not able to be processed.',
        });
      }

      this.$emit('save', result.data);
      return this.$notify.success({
        message: result.message,
      });
    },

    async handleSave() {
      this.saving = true;
      if (this.fileList.length > 0) {
        const payload = {
          id: this.ip.id,
          name: this.$refs.editor.form.name,
          date: moment.utc().format('YYYY-MM-DD'),
          organization_id: this.$refs.editor.form.organization.id,
        };

        if (Array.isArray(this.$refs.editor.form.pixel_partners) && this.$refs.editor.form.pixel_partners) {
          payload.pixel_partners = this.$refs.editor.form.pixel_partners;
        }

        await this.$refs.uploader.startUpload(payload);
      }

      this.resetForm();
    },

    resetForm() {
      this.fileList = [];
      this.$refs.uploader.reset();
      this.saving = false;
      this.loading = false;
      this.pixel = {};
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style scoped>
:deep(.org-picker),
:deep(.tag-picker) {
  width: calc(100% - 150px) !important;
  display: inline-block;
}

.el-input {
  width: 320px;
}

pre {
  display: inline;
  color: #CC5566;
}
</style>
