<template>
  <edit-dialog
    ref="editDialog"
    :data="{}"
    object-name="IP to MAID Snapshot Audience"
    label-width="150px"
    :visible="visible"
    width="50%"
    :save-disabled="!canSave"
    :save-state="saving"
    @request-close="$emit('update:visible', false)"
    @save="() => handleSave()"
  >
    <el-form v-loading="busy">
      <div v-if="noData">
        <h3>There is no data available</h3>
        <p>Sorry, your ip list does not have any data yet, there is nothing to snapshot.</p>
        <p>Please allow 24 to 48 hours for data collection and ingestion.</p>
      </div>
      <div v-else>
        <el-form-item
          label="Name"
        >
          <el-input v-model="formData.name" />
          <div>
            <dedupe-control v-model="formData.auto_dedupe" />
          </div>
        </el-form-item>
        <el-form-item
          label="Date Range"
        >
          <date-picker
            :date-range.sync="formData.dateRange"
            :from="earliestDate"
            :to="latestDate"
          />
        </el-form-item>
        <el-form-item
          label="Organization"
        >
          <org-picker
            :organization.sync="formData.organization"
          />
        </el-form-item>

        <el-form-item
          label="Tags"
        >
          <tag-picker :tags.sync="formData.tags" />
        </el-form-item>
      </div>
    </el-form>
  </edit-dialog>
</template>

<script>
import { audience as audienceApi, pixel as pixelApi } from '@/adonis-api';
import { dateToString } from '@/helpers';

import DatePicker from '@/components/global/DatePicker.vue';
import DedupeControl from '@/components/global/DedupeControl.vue';
import EditDialog from '@/components/global/EditDialog.vue';
import OrgPicker from '@/components/global/OrgPicker.vue';
import TagPicker from '@/components/global/TagPicker.vue';

export default {
  name: 'IpAudienceForm',
  components: {
    DatePicker,
    DedupeControl,
    EditDialog,
    OrgPicker,
    TagPicker,
  },

  props: {
    pixelId: { type: Number, default: null },
    visible: { type: Boolean, required: true },
  },

  data() {
    return {
      busy: false,
      formData: {
        auto_dedupe: this.$store.state.user.userDetails.preferences.options.auto_dedupe,
        dateRange: [],
        name: null,
        organization: {},
        tags: [],
      },
      pixel: {},
      saving: false,
    };
  },

  computed: {
    canSave() {
      return this.formData.name &&
             this.formData.organization.id &&
             this.formData.dateRange[0] &&
             this.formData.dateRange[1];
    },

    earliestDate() {
      if (!this.pixel.data) {
        return new Date();
      }

      const dates = this.pixel.data.map(d => d.visit_date).sort();

      if (dates.length > 0) {
        return dates[0];
      }

      return new Date();
    },

    latestDate() {
      if (!this.pixel.data) {
        return new Date();
      }

      const dates = this.pixel.data.map(d => d.visit_date).sort().reverse();

      if (dates.length > 0) {
        return dates[0];
      }

      return new Date();
    },

    noData() {
      if (!this.busy) {
        if (this.pixel.data && this.pixel.data.length < 1) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    async visible(value) {
      this.reset();

      if (value && this.pixelId) {
        // Lookup pixel
        this.busy = true;
        this.pixel = await pixelApi.findOne(this.pixelId);

        this.formData.organization = Object.freeze(this.pixel.organization);
        this.formData.name = this.pixel.name;
        this.formData.tags = this.pixel.tags;

        this.busy = false;
      } else {
        this.pixel = {};
      }
    },
  },

  methods: {
    async handleSave() {
      this.saving = true;

      const payload = {
        name: this.formData.name,
        auto_dedupe: this.formData.auto_dedupe,
        organization_id: this.formData.organization.id,
        pixel_id: this.pixel.id,
        type: 'IP',
        start_date: dateToString(this.formData.dateRange[0]),
        end_date: dateToString(this.formData.dateRange[1]),
      };

      try {
        await audienceApi.createPixelAudience(payload);

        this.$notify.success({
          message: 'Your audience has been saved.',
        });

        this.reset();
        this.$router.push('/audiences/library/list');
      } catch (error) {
        this.$notify.error({
          message: 'An error occurred while saving the audience. This issue has been reported.',
        });
        this.$reportError(error, {
          componentName: this.$options.name,
          params: payload,
        });
      } finally {
        this.saving = false;
        this.$emit('update:visible', false);
      }
    },

    reset() {
      this.formData = {
        auto_dedupe: this.$store.state.user.userDetails.preferences.options.auto_dedupe,
        dateRange: [],
        name: null,
        organization: {},
        tags: [],
      };
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
